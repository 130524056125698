@use 'sass:math';

body {
  &.tmpl-landingpage {
    --body-bg: transparent;
    --content-bg: transparent;
    --content-padding: 0;
    --heading-h2-size: 23px;

    --hero-bg-color: #e0eeef;
    --hero-bear-size: 160px;
    --hero-bear-position: left calc(100% + 60px);

    @media (min-width: $screen-xs) {
      --hero-bear-size: 35%;
      --hero-bear-position: left bottom;
    }

    @media (min-width: $screen-md) {
      --heading-h2-size: 40px;
    }

    @media (min-width: $screen-lg-desktop) {
      --hero-bear-size: 360px;
      --hero-bg-width: #{$container-large-desktop + ($grid-gutter-width * 5)};
    }

    .header {
      background: none;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding: 75px 0 0 0;
      overflow: hidden;

      & > .container {
        grid-row: 1;
        grid-column: 1;
        place-self: center;
        z-index: 2;
      }

      &:before,
      &:after {
        content: '';
        grid-row: 1;
        grid-column: 1;
        width: 100%;
        height: 100%;
      }

      &:after {
        background: var(--hero-bg-color);
      }

      &:before {
        z-index: 1;
        place-self: center;
        background:
          url(../Images/Landingpage/bear.png) var(--hero-bear-position) / var(--hero-bear-size) no-repeat,
          url(../Images/Landingpage/stars.png) right top / 270px no-repeat;

        width: var(--hero-bg-width, 100%);
      }
    }

    .content-teaser {
      --lead-color: #{$beige-dark};
      --heading-h2-color: #{$blue-content};

      .cta-link.btn-like {
        @extend .arrow-brown-full;
      }

      h2 {
        font-size: var(--heading-h2-size);
        margin-block: 25px;
      }

      width: var(--content-width, 100%);
      margin-inline: auto;
    }

    .m-organizationfacts {
      width: var(--content-width, 100%);
      margin-inline: auto;
    }

    .m-membershipteaser {
      --membership-color: #{$text-color};
      --membership-border: #{$blue-lines};
      --membership-hover: transparent;

      align-items: initial;
    }

    .logos {
      background: $white-content;

      .m-logo {
        &--item {
          flex: 1 0 20%;
          flex-wrap: wrap;

          &:first-child {
            float: none;
            width: auto;
          }

          img {
            object-fit: contain;
          }
        }
      }
    }

    main {
      @media (min-width: $screen-lg) {
        --content-width: #{percentage(math.div(10, 16))};
        --content-width-medium: #{percentage(math.div(12, 16))};
        --content-width-wide: #{percentage(math.div(14, 16))};
      }

      & > .content {
        & > .frame:not(.frame-type-textmedia),
        & .textmedia-container {
          @extend .container;
          padding-top: 50px;
          padding-bottom: 50px;

          h2 {
            margin-top: 0;
          }

          > .column-wrap {
            width: var(--content-width, 100%);
            margin-inline: auto;
          }
        }

        .frame {
          &-layout-1702451053 {
            @extend .bg-color;
          }
        }
      }

      .bg-image {
        height: auto;
        background-size: cover !important;
        max-width: 1920px;
        margin: 0 auto;

        h2 {
          font-family: $font-family-sans-serif-2;
          font-size: 23px;
          hyphens: auto;
          text-wrap: balance;
          margin-top: 0;

          @media (min-width: $screen-md) {
            font-size: 40px;
            text-wrap: normal;
            hyphens: none;
          }

          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .m-cite--item {
        display: flex;
        flex-direction: column;
        width: var(--content-width-medium, 100%);
        margin-inline: auto;
      }

      .m-faq {
        width: var(--content-width, 100%);
        margin-inline: auto;
      }

      & + footer {
        padding: 0;
      }

      & > .intro-teaser {
        & > .frame {
          margin-bottom: $grid-gutter-width * 2;
        }
      }
    }

    .bg-color {
      background: var(--bg-color, #{$beige});
    }

    .slicknav_nav {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

      .navbar-nav {
        li {
          a {
            background-color: #2469a8;
            border-bottom: 1px solid #e2ebf5;
            color: #fff;
            padding: 20px;
            text-align: left;
          }
        }
      }
    }
  }
}
