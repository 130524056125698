.m-share {
  margin-top: 5px;
  .shariff {
    ul {
      li.shariff-button {
        list-style-type: none;
        background: #999;
        display: inline-block;
        padding: 0;
        margin: 0;
        text-align: center;
        a {
          color: white;
          display: block;
          width: 45px;
          margin: 10px auto;
          .share_text {
            display: none;
          }
        }
        &.facebook {
          background-color: #3b5997;
        }
        &.twitter {
          background-color: #55acee;
        }
        &:before {
          display: none;
        }
      }
    }
  }
}
