// teaser deeplinks < 992px
section.color-brown {
  padding: 0;
}

.m-membershipteaser {
  border: none;
  display: inline;
  float: left !important;
  margin-top: 0;
  width: 100%;

  .m-membershipteaser--item {
    margin: 0;
    width: 100%;

    .media-body {
      margin: 0;
      vertical-align: middle;
      width: inherit;

      h4 {
        font-size: $font-size-large;
        margin: 0;
        width: 100%;
      }

      a {
        color: var(--membership-color, $white);
        border-bottom: 1px solid $blue-lines-membershipteaser;
        display: block;
        padding: 20px 10px;
        margin: 0;

        &:hover {
          background-color: $blue-membershipteaser-hi;
        }
      }

      p {
        display: none;
      }
    }
  }
}

// teaser deeplinks tablet
@media screen and (max-width: $screen-md-min) {
  .m-membershipteaser {
    margin: 40px 0 0 0;

    .m-membershipteaser--item {
      border-bottom: 1px solid var(--membership-border, $blue-lines-membershipteaser);
      display: inline;
      float: left !important;
      padding: 20px 0;
      border-right: none !important;

      &:first-child {
        border-top: 1px solid var(--membership-border, $blue-lines-membershipteaser);
      }

      &:hover {
        background-color: var(--membership-hover, $blue-membershipteaser-hi);
        border-bottom: 1px solid var(--membership-border, $blue-lines-membershipteaser);
      }

      .media-left {
        img {
          max-height: 50px;
          min-width: 120px;
        }
      }

      .media-body {
        width: inherit;

        h4 {
          color: var(--membership-color, $white);
          font-size: 18px;
          font-weight: 300;
        }

        a {
          color: $white;
          display: block;
          padding: 20px 10px;
          margin: 0;
        }

        p {
          color: $white;
          font-size: $font-size-medium;
        }
      }
    }
  }
}

// teaser deeplinks desktop > 992px
@media screen and (min-width: $screen-md-min) {
  .m-membershipteaser {
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 40px 0 0 0;

    .m-membershipteaser--item {
      border-right: 1px solid var(--membership-border, $blue-lines-membershipteaser);
      padding: 20px 40px;
      text-align: center;

      &:last-of-type {
        border: none;
      }

      .media-left {
        display: block;
        align-content: center;
        padding-right: 0 !important;
        min-height: 130px;

        .media-object {
          display: inline;
        }
      }

      .media-body {
        width: inherit;

        h4 {
          color: var(--membership-color, $white);
          font-size: 26px;
          margin: 10px 0 25px 0;
          line-height: 1.25;
        }

        a {
          border-bottom: none;
          color: $white;
          display: block;
          margin: 0;
          padding: 0 10px;

          &:hover {
            background-color: transparent;
          }
        }

        p {
          color: var(--membership-color, $white);
          font-size: $font-size-medium;
          line-height: 1.5;
          display: block;
        }
      }
    }
  }
}
