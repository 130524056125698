.m-sidebar-teaser {
  color: $brand-primary;
  font-size: $font-size-medium;
  border-top: 1px solid $brand-primary;
  padding-top: 30px;
  margin-top: 50px;
  h4 {
    font-size: $font-size-xmedium;
  }
  p {
    margin: 0 0 15px;
  }
  ul li {
    margin: 0 0 10px 0;
    padding-left: 25px;
    &:before {
      content: url('../Images/arrow_ul_blue.svg');
      background-size: cover;
      position: relative;
      display: inline-block;
      float: left;
      top: 0;
      margin: 2px 0 0 -25px;
      width: 17px;
      height: 17px;
    }
  }
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 7px 10px;
  }
  .cta-link {
    padding: 11px 20px 10px 0;
    margin-top: 10px;
    &.arrow-brown {
      background-size: 15px;
    }
  }
}
