:root {
  @each $type in (1, 2, 3, 4, 5) {
    --heading-h#{$type}-color: inherit;
  }
}

@each $type in (1, 2, 3, 4, 5) {
  h#{$type} {
    color: var(--heading-h#{$type}-color);
  }
}
