.m-news {
  .m-news-detail {
    .intro {
      font-size: 20px;
    }
  }
  .m-news-list {
    &.m-news-list_default {
      .m-news-list__header {
        h2 {
          &.lead {
            color: $beige-dark;
            font-family: $font-family-sans-serif;
            font-size: $font-size-medium;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
        }
      }
      .m-news-list__articles {
        display: flex;
        flex-flow: row wrap;
        .m-news-list__article {
          padding: 20px 0;
          &:first-of-type {
            border-left: none;
          }
          a {
            color: $gray-base;
          }
          .lead {
            color: $blue;
            font-size: $font-size-small;
            margin: 15px 0 0 0;
          }
          h3 {
            font-size: 22px;
            margin-top: 5px;
            line-height: 1;
          }
          .teaser {
            p {
              font-size: $font-size-medium;
            }
          }
          .cta-link {
            padding: 15px 30px 15px 0;
          }
          &.m-news-list__article_big {
            h3 {
              font-size: 22px;
              line-height: 1;
            }
          }
        }
        .row {
          display: table;
          width: 100%;
        }
      }
    }
    &.m-news-list_latestsidebar {
      margin-top: 55px;
      h4 {
        border-bottom: 1px solid $brand-primary;
        color: $brand-primary;
        font-size: $font-size-small;
        letter-spacing: 1.1px;
        text-transform: uppercase;
      }
      .m-news-list__item {
        border-bottom: 1px solid $brand-primary;
        padding-bottom: 30px;
        a {
          color: $gray-base;
          .lead {
            color: $brand-primary;
            font-size: $font-size-small;
            margin: 40px 0 0 0;
            text-transform: uppercase;
          }
          .linkheader.h2 {
            font-size: $font-size-h2-content;
            margin-top: 5px;
            line-height: 1;
          }
          p {
            font-size: $font-size-small;
            margin: 10px 0;
          }
          .cta-link {
            font-size: $font-size-small;
            padding: 0 25px 0 0;
          }
        }
        &:last-of-type {
          border-bottom: none;
          margin-bottom: 20px;
        }
      }
    }
    &.m-news-list_latestFooter {
      .m-news-list__header {
        h2 {
          &.lead {
            color: $beige-dark;
            font-family: $font-family-sans-serif;
            font-size: $font-size-medium;
            margin-top: 15px;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
        }
      }
      .m-news-list__articles {
        display: flex;
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: space-between;
        .m-news-list__article {
          padding: 20px 0;
          &::before,
          &::after {
            content: '';
            width: 100%;
          }
          &:first-of-type {
            border-left: none;
          }
          a {
            color: $gray-base;
          }
          .lead {
            color: $brand-primary;
            font-size: $font-size-small;
            margin: 15px 0 0 0;
          }
          h3 {
            font-size: $font-size-h2-content;
            margin-top: 5px;
            line-height: 1;
          }
          .teaser {
            p {
              font-size: $font-size-medium;
            }
          }
          .cta-link {
            padding: 15px 30px 15px 0;
          }
          &.m-news-list__article_big {
            float: left;
            width: 50%;
            h3 {
              font-size: 36px;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
hr.m-news-borderTop {
  border-top: 1px solid $blue-lines;
  width: 100%;
}

.m-news-list__pagination {
  display: block;
  width: 100%;
  ul {
    display: flex;
    justify-content: center;

    li {
      margin: 0 5px;
      padding: 0;
      &:before {
        content: '';
      }
      a {
        border: 1px solid $beige-dark;
        background: transparent;

        &:hover,
        &:focus {
          background-color: $white;
          border: 1px solid $beige-dark;
          color: $beige-dark;
        }
      }
      &.current {
        > a,
        > a:hover,
        > span,
        > span:hover {
          background-color: $beige-dark;
          border: 1px solid $beige-dark;
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
          color: $white;
        }
      }
    }
  }
}

// news tablet
@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .m-news {
    .m-news-detail {
      .intro {
        font-size: 26px;
      }
    }
    .m-news-list {
      &.m-news-list_default {
        .m-news-list__header {
          h2 {
            &.lead {
              color: $beige-dark;
              font-family: $font-family-sans-serif;
              font-size: $font-size-medium;
              letter-spacing: 2px;
              text-transform: uppercase;
            }
          }
        }
        .m-news-list__articles {
          .m-news-list__article {
            padding: 20px;
            float: left;
            width: 50%;
            a {
              color: $gray-base;
            }
            .lead {
              color: $brand-primary;
              font-size: $font-size-small;
              margin: 15px 0 0 0;
            }
            h3 {
              font-size: $font-size-h2-content;
              margin-top: 5px;
              line-height: 1;
            }
            .teaser {
              p {
                font-size: $font-size-medium;
              }
            }
            .cta-link {
              padding: 15px 30px 15px 0;
            }
            &.m-news-list__article_big {
              float: left;
              width: 50%;
              h3 {
                font-size: 36px;
                line-height: 1;
              }
            }
          }
        }
      }
      &.m-news-list_latestsidebar {
        margin-top: 55px;
        h4 {
          border-bottom: 1px solid $brand-primary;
          color: $brand-primary;
          font-size: $font-size-small;
          letter-spacing: 1.1px;
          text-transform: uppercase;
        }
        .m-news-list__item {
          border-bottom: 1px solid $brand-primary;
          padding-bottom: 30px;
          a {
            color: $gray-base;
            .lead {
              color: $brand-primary;
              font-size: $font-size-small;
              margin: 40px 0 0 0;
              text-transform: uppercase;
            }
            .linkheader.h2 {
              font-size: $font-size-h2-content;
              margin-top: 5px;
              line-height: 1;
            }
            p {
              font-size: $font-size-small;
              margin: 10px 0;
            }
            .cta-link {
              font-size: $font-size-small;
              padding: 0 25px 0 0;
            }
          }
        }
      }
      &.m-news-list_latestFooter {
        .m-news-list__header {
          h2 {
            &.lead {
              color: $beige-dark;
              font-family: $font-family-sans-serif;
              font-size: $font-size-medium;
              margin-top: 15px;
              letter-spacing: 2px;
              text-transform: uppercase;
            }
          }
        }
        .m-news-list__articles {
          display: flex;
          flex-flow: row wrap;
          align-content: space-between;
          justify-content: space-between;
          .m-news-list__article {
            padding: 20px;
            float: left;
            width: 50%;
            &:nth-child(2n) {
              border-left: 1px solid $blue-lines;
            }
            &:nth-child(2n + 1) {
              padding-left: 0;
              border-left: 1px solid $blue-lines;
            }
            &:nth-child(2n + 1) {
              padding-left: 0;
              border-left: none;
            }
            &:nth-child(2n + 2) {
              padding-right: 0;
              border-left: 1px solid $blue-lines;
            }
            &:first-of-type {
              border-left: none;
            }
            &:last-of-type {
              clear: both;
            }
            a {
              color: $gray-base;
            }
            .lead {
              color: $brand-primary;
              font-size: $font-size-small;
              margin: 15px 0 0 0;
            }
            h3 {
              font-size: $font-size-h2-content;
              margin-top: 5px;
              line-height: 1;
            }
            .teaser {
              p {
                font-size: $font-size-medium;
              }
            }
            .cta-link {
              padding: 15px 30px 15px 0;
            }
          }
        }
      }
    }
  }
  hr.m-news-borderTop {
    display: none;
  }
}

// news > 992px
@media screen and (min-width: $screen-md-min) {
  .m-news {
    .m-news-detail {
      .intro {
        font-size: 26px;
      }
    }
    .m-news-list {
      &.m-news-list_default {
        .m-news-list__header {
          h2 {
            &.lead {
              color: $beige-dark;
              font-family: $font-family-sans-serif;
              font-size: $font-size-medium;
              letter-spacing: 2px;
              text-transform: uppercase;
            }
          }
        }
        .m-news-list__articles {
          .m-news-list__article {
            border-left: 1px solid $blue-lines;
            padding: 20px;
            float: left;
            width: 25%;
            &:nth-child(5n) {
              border-left: none;
            }
            &:nth-child(n + 4) {
              border-bottom: none;
            }
            &:first-of-type {
              border-left: none;
            }
            a {
              color: $gray-base;
            }
            .lead {
              color: $brand-primary;
              font-size: $font-size-small;
              margin: 15px 0 0 0;
            }
            h3 {
              font-size: $font-size-h2-content;
              margin-top: 5px;
              line-height: 1;
            }
            .teaser {
              p {
                font-size: $font-size-medium;
              }
            }
            .cta-link {
              padding: 15px 30px 15px 0;
            }
            &.m-news-list__article_big {
              float: left;
              width: 50%;
              h3 {
                font-size: 36px;
                line-height: 1;
              }
            }
          }
        }
      }
      &.m-news-list_latestsidebar {
        margin-top: 55px;
        h4 {
          border-bottom: 1px solid $brand-primary;
          color: $brand-primary;
          font-size: $font-size-small;
          letter-spacing: 1.1px;
          text-transform: uppercase;
        }
        .m-news-list__item {
          border-bottom: 1px solid $brand-primary;
          padding-bottom: 30px;
          a {
            color: $gray-base;
            .lead {
              color: $brand-primary;
              font-size: $font-size-small;
              margin: 40px 0 0 0;
              text-transform: uppercase;
            }
            .linkheader.h2 {
              font-size: $font-size-h2-content;
              margin-top: 5px;
              line-height: 1;
            }
            p {
              font-size: $font-size-small;
              margin: 10px 0;
            }
            .cta-link {
              font-size: $font-size-small;
              padding: 0 25px 0 0;
            }
          }
        }
      }
      &.m-news-list_latestFooter {
        .m-news-list__header {
          h2 {
            &.lead {
              color: $beige-dark;
              font-family: $font-family-sans-serif;
              font-size: $font-size-medium;
              letter-spacing: 2px;
              text-transform: uppercase;
            }
          }
        }
        .m-news-list__articles {
          .m-news-list__article {
            padding: 20px;
            float: left;
            width: 25%;
            a {
              color: $gray-base;
            }
            .lead {
              color: $brand-primary;
              font-size: $font-size-small;
              margin: 15px 0 0 0;
            }
            h3 {
              font-size: $font-size-h2-content;
              margin-top: 5px;
              line-height: 1;
            }
            .teaser {
              p {
                font-size: $font-size-medium;
              }
            }
            .cta-link {
              padding: 15px 30px 15px 0;
            }
          }
        }
      }
    }
  }
}
