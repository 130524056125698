$supi-colors: (
  background: #fff,
  primary: #000,
  secondary: #fff,
  button-bar: #efefef,
  success: $brand-primary,
  warning: #fff,
  notice: $brand-primary,
  underline: #6c757d,
  button-wrap-bgcolor: #efefef,
  cookie-table-bgcolor: #f8f9fa,
);

@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

.tx-supi {
  a,
  button,
  &__link-button {
    &:focus-visible {
      overflow: visible;
    }
    // Duplicated because some legacy browser suppressing the rule completely
    &.focus-visible {
      overflow: visible;
    }
  }

  button {
    border-radius: 3px;
    padding: 5px 10px;

    &#supi__individualSwitchTo {
      color: var(--supi-color-individual);
    }

    &#supi__dismiss {
      border: 2px solid var(--supi-color-individual);
      color: var(--supi-color-individual);
    }

    &#supi__allow {
    }
  }

  &__block-item {
    &__text {
      margin: $grid-gutter-width / 4 0;
    }
  }

  &.theme-default {
    --supi-font-size: 16px;
    --supi-banner-border: 1px solid transparent;
    --supi-banner-box-shadow: 0 0 8px 4px rgb(34, 34, 34, 0.1);
  }
}
