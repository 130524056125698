.m-loginteaser {
  background-color: $blue-form-bg;
  border: 1px solid #ecf1f1;
  color: $blue;
  margin: 0 5px 5px 0;
  width: calc(100% - 5px);
  @include box-shadow(5px 5px 0px rgba(239, 243, 243, 1));
  padding: 20px 40px;
  > .panel.panel-login {
    background-color: transparent;
    box-shadow: none;
  }
  h5 {
    font-size: $font-size-h3;
    margin-top: 25px;
    margin-bottom: 0;
  }
  h6 {
    color: $blue;
    font-weight: 700;
    font-size: $font-size-h5;
    margin-top: 2px;
  }
  p,
  .m-loginteaser__teaser {
    color: $blue;
  }
  .media {
    margin-bottom: 30px;
  }
  .col-md-8:last-of-type {
    border-top: 1px solid rgba(226, 235, 245, 1);
    padding-top: 20px;
    margin-top: 10px;
  }
  label {
    display: none;
  }
  input {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    width: 80%;
    @include box-shadow(2px 2px 3px rgba(196, 200, 203, 1));
  }
  .btn {
    margin-left: 0;
    margin-right: 0;
    margin-right: 10px;
  }
  .btn-like {
    padding-left: 0;
  }
  .btn-login {
    float: left;
    background-color: rgba(178, 190, 199, 1) !important;
  }
  .password {
    display: inline-block;
    margin-top: 32px;
  }
}

@media screen and (min-width: $screen-md-min) {
  .m-loginteaser {
    .col-md-8:last-of-type {
      border-left: 1px solid rgba(226, 235, 245, 1);
      border-top: none;
      padding-top: 0;
      margin-top: 0;
      padding-left: 25px;
    }
    .btn {
      margin-bottom: 0;
    }
    .password {
      margin-top: 32px;
      display: inline-block;
    }
  }
}
