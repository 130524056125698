.m-faq {
}

.panel-faq {
  border-top: 1px solid $blue-lines;
  box-shadow: none;
  background-color: transparent;

  margin-bottom: 0;
  border-radius: 0;
  &:last-of-type {
    border-bottom: 1px solid $blue-lines;
    margin-bottom: 25px;
  }

  .panel {
    &-heading {
      padding: 0 0;
      border-bottom: none;
      border-radius: 0px;
    }
    &-title {
      color: $brand-primary;
      a {
        color: inherit;
        padding: 25px 60px 25px 21px;
        display: block;
        background: {
          image: url('../Images/arrow_blue-down.svg');
          repeat: no-repeat;
          position: right 21px center;
          color: transparent;
          size: 17px;
        }
        &:not(.collapsed) {
          background-image: url('../Images/arrow_blue-up.svg');
          background-color: white;
        }
      }
    }
    &-collapse {
      background-color: white;
      padding: 0 22px;
      > :last-child {
        padding-bottom: 50px;
        margin-bottom: 0;
      }
      &.collapse.in:before {
        display: none !important;
      }
    }
  }
}
