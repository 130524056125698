footer {
  padding: 50px 0 0 0;
  .contact {
    border-left: 1px solid $blue-lines;
    padding: 0 0 20px 0;
    margin-left: 0;
    padding-left: 6.25%;
  }
  h4 {
    color: $beige-dark;
    font-size: $font-size-small;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  ul {
    margin: 0 0 50px 0;
    padding: 0;
    list-style-type: none;
    li {
      a {
        color: $blue;
        margin: 10px 0;
        line-height: 20px;
        display: block;
      }
    }
    &.footer-2 {
      li {
        float: left;
        position: relative;
        a {
          color: $blue;
          margin: 20px 20px 20px 0;
          line-height: 20px;
          display: block;
        }
      }
    }
  }
  .footer-2-icons {
    height: 60px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      float: left;
      a {
        color: $beige-dark;
        margin: 15px 0 15px 5px;
      }
    }
  }
}

@media (max-width: 992px) {
  footer {
    text-align: center;
    /* http://stackoverflow.com/questions/2865380/how-do-i-center-align-horizontal-ul-menu */
    .col-md-13 {
      float: right;
      position: relative;
      left: -50%;
      text-align: left;
    }
    > .container {
      .col-md-3,
      .col-md-4 {
        float: left;
        height: auto;
        width: 33.33%;
      }
      .col-md-5 {
        display: block;
        float: none;
        width: 100%;
        &.contact {
          padding-left: 0;
          border-left: none;
        }
      }
    }
    ul.footer-2 {
      list-style: none;
      position: relative;
      left: 50%;
      li a {
        margin: 10px;
      }
    }
    .footer {
      text-align: center;
      &__list {
        clear: both;
        text-align: center;
        display: table;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  footer {
    .col-md-13 {
      float: none;
      position: relative;
      left: 0;
      text-align: left;
    }
    ul {
      &.footer-2 {
        list-style: none;
        position: inherit;
        left: inherit;
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  footer {
    .footer {
      .col-md-3,
      .col-md-4 {
        float: none;
        width: 100%;
        clear: left;
        ul {
          display: block;
          float: none;
          li {
            justify-content: center;
            list-style: none;
            a {
              margin: 5px 8px 5px 0;
            }
          }
        }
      }
      .footer-2-icons {
        display: table !important;
        height: 60px;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
        li {
          justify-content: center;
          float: left;
          a {
            color: $beige-dark;
            margin: 15px 0 15px 5px;
          }
        }
      }
    }
  }
}
