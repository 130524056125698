#pagination {
  &.pagination-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .pagination {
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &__item {
        padding: 6px 12px;
        margin: 0 5px;
        border: 1px solid $beige-dark;
        color: $beige-dark;
        margin-bottom: 10px;

        &.disabled {
          color: #777;
          background-color: #fff;
          border-color: #ddd;
          cursor: not-allowed;
        }

        a {
          color: inherit;
        }

        &-current {
          background: $beige-dark;
          color: $white;
        }

        &:before {
          display: none;
        }
      }
    }
  }
}
