nav {
  z-index: 9;
  .nav {
    > li {
      > a {
        &:hover,
        &:focus {
          background: $white;
          color: $blue-content;
        }
      }
    }
  }
  .container .navbar-header {
    margin-left: 0;
  }
  @media (min-width: 992px) {
    .btn {
      margin: 20px auto;
      float: none;
    }
  }
  .btn {
    margin: 20px;
  }
  &.navbar-fixed-top {
    background-color: rgba(255, 255, 255, 1);
    border: 0;
    @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
    &.loggedin {
      background-color: $blue;
      .navbar-nav > li.active,
      .navbar-nav > li:hover {
        background-color: $beige-dark;
      }
      .navbar-nav {
        li {
          &:hover {
            > a {
              color: $beige-dark;
            }
          }
          a {
            background-color: $blue;
            color: $white;
            &:hover {
              color: $beige-dark;
            }
          }
          ul li a {
            background-color: $white;
            color: $blue;
            &:hover {
              background-color: $blue;
              color: $white;
            }
          }
        }
        .active > a {
          background-color: $blue;
          color: $beige-dark;
        }
      }
    }
  }
  .navbar-toggle {
    .icon-bar {
      background-color: $blue;
      width: 25px;
      height: 3px;
      + .icon-bar {
        margin-top: 5px;
      }
    }
  }

  .navbar-right .dropdown-menu {
    right: 0;
    left: 0;
  }
  .navbar-brand {
    padding: 0 10px;
  }
  button {
    margin-top: 9px;
  }
}

.slicknav_menu {
  //display: none;
}

nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

.navbar-header img {
  position: relative;
  z-index: 99;
  max-width: 220px;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 25px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav {
    *zoom: 1;
    list-style: none;
    :before,
    :after {
      content: '';
      display: table;
    }
    :after {
      clear: both;
    }
    > li {
      float: left;
      position: relative;
      padding: 0 0 5px 0;
      &.active,
      &:hover {
        background-color: $brand-primary;
        a {
          color: $brand-primary;
          padding-bottom: 20px;
        }
      }
    }
    a {
      display: block;
      background-color: $white;
      line-height: 1.2em;
      color: $gray-base;
      font-size: 17px;
      padding: 30px 20px 20px 20px;
      &:hover {
        text-decoration: none;
        background-color: $white;
      }
    }

    li ul {
      position: absolute;
      left: 0;
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      list-style: none;
      margin: 0;
      padding: 5px 0 0 0;
      @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
      filter: alpha(opacity=0);
      -webkit-transition: 200ms ease;
      -moz-transition: 200ms ease;
      -o-transition: 200ms ease;
      transition: 200ms ease;
      li ul {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
        filter: alpha(opacity=0);
        -webkit-transition: 200ms ease;
        -moz-transition: 200ms ease;
        -o-transition: 200ms ease;
        transition: 200ms ease;
        left: 100%;
        background-color: none;
        top: 0;
      }
    }
    li:last-child ul {
      li ul {
        left: -100%;
        max-width: 260px;
      }
    }
    > li:hover > ul,
    > li > ul li:hover ul {
      visibility: visible;
      opacity: 1;
      filter: alpha(opacity=100);
    }
    > li ul li {
      border: none;
      background-color: $beige;
      border-bottom: 1px solid $blue-lines;
      padding: 0;
      margin: 0;
      min-width: 250px;
      position: relative;
      &.hasSubMenu {
        background-color: $white;
        height: 100%;
        a {
          height: 100%;
          padding-right: 60px;
          &:after {
            content: '\f105';
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            position: absolute;
            float: right;
            display: block;
            border-left: 1px solid #e2ebf5;
            width: 60px;
            height: 100%;
            text-align: center;
            right: 0;
            top: 0;
            padding-top: 20px;
            overflow: hidden;
          }
        }
        ul li a {
          &:after {
            content: '';
            border-left: none;
            width: inherit;
            height: inherit;
            text-align: inherit;
          }
        }
      }
    }
    li ul a {
      border: none;
      color: $blue-content;
      font-size: 17px;
      padding: 20px;
    }
    li ul a:hover {
      color: $white;
      background-color: $blue-subnav-hi;
    }
  }
}

@media screen and (max-width: 1199px) {
  .slicknav_nav {
    max-height: 90vh;
    overflow-y: auto;
  }
  .navbar-nav {
    margin: 0;
  }
  #navbar {
    display: none !important;
  }
  .js .slicknav_menu {
    display: inline-block;
  }
}

.slicknav_nav {
  ul ul ul {
    .hasSubMenu.hasSubMenu.hasSubMenu {
      background: blue;
      .slicknav_parent-link {
        border-bottom-color: #e2ebf5;

        a {
          background: #eeede9;

          &:hover {
            color: $beige-dark;
          }

          & + a {
            display: none;
          }
        }
      }
    }
  }
}
