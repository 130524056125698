@use 'sass:math';

.textmedia {
  display: flex;
  flex-direction: var(--text-media-direction, column);
  gap: var(--text-media-gap, #{$grid-gutter-width});
  align-items: var(--text-media-align, center);

  &__order {
    // image beside
    &-26,
    &-25 {
      @media (min-width: $screen-sm) {
        [class*='col-'] & {
          --text-media-direction: column;
          --text-media-media-width: 100%;
          --text-media-gap: #{$grid-gutter-width};
        }

        --text-media-gap: #{$grid-gutter-width * 3};
        --text-media-direction: row;
        --text-media-media-width: calc(#{percentage(math.div(8, 16))} - calc(var(--text-media-gap) * 0.5));
      }
    }
  }

  &__bodytext {
    width: var(--text-media-media-width, 100%);
    flex: 1 0 var(--text-media-media-width, 100%);

    p:last-child {
      margin: 0;
    }
  }

  &__media {
    // nasty hack ;)
    &.textmedia__media {
      display: flex;
      justify-content: center;
      flex-direction: column;

      margin: 0;
    }

    width: var(--text-media-media-width, 100%);
    flex: 1 0 var(--text-media-media-width, 100%);
  }
}
