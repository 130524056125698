$availableFileIcons: avi doc docx img mp3 pdf ppt pptx txt zip;

ul.downloads {
  li {
    margin: 0 0 40px 0;
    padding: 0;
    &:before {
      content: '';
      background-size: inherit;
      position: inherit;
      display: inherit;
      top: inherit;
      margin: inherit;
      width: inherit;
      height: inherit;
    }
    a,
    a:hover {
      color: $gray-base;
      .fileicon {
        float: left;
        margin: 6px 1em 0 0;
        vertical-align: top;
        height: 43px;
        width: 33px;
        background: url('../Images/FileIcons/icon_generic.svg') no-repeat;
        background-size: contain;
        &-type- {
          @each $fileIcon in $availableFileIcons {
            &#{$fileIcon} {
              background-image: url('../Images/FileIcons/icon_#{$fileIcon}.svg');
            }
          }
        }
      }
      .ce-uploads-fileName {
        font-weight: 700;
      }
    }
  }
}
