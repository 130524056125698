// teaser deeplinks < 992px
@media screen and (max-width: $screen-sm-min) {
  section.color-brown {
    padding: 0;
  }
  .m-deeplinkteaser .container .row {
    display: table;
  }
  .m-deeplinkteaser--item {
    border: none;
    border-bottom: 1px solid #6d665a;
    display: inline;
    float: left !important;
    margin-top: 0;
    width: 100%;
    a {
      display: block;
      padding: 20px 10px;
    }
    .media-body {
      margin: 0;
      vertical-align: middle;
      width: inherit;
      h4 {
        font-size: $font-size-large;
        margin: 0;
        width: 100%;
        &:after {
          font-family: FontAwesome;
          content: '\f105';
          right: 35px;
          position: absolute;
        }
      }
      p {
        display: none;
      }
    }
  }
}

// teaser deeplinks tablet
@media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
  .m-deeplinkteaser--item {
    border-right: 1px solid #6d665a;
    border-bottom: 1px solid #6d665a;
    display: inline;
    float: left !important;
    margin: 0;
    padding: 0 20px;
    width: 50%;
    &:nth-child(2n) {
      border-right: none !important;
    }
    a {
      display: block;
      padding: 20px 10px;
    }
    .media-body {
      text-align: left;
      width: inherit;
      h4 {
        font-size: $font-size-xmedium;
        font-weight: 400;
        margin: 5px 0;
      }
      p {
        font-size: $font-size-small;
      }
    }
  }
}

// teaser deeplinks > 992px
@media screen and (min-width: $screen-md-min) {
  .m-deeplinkteaser .container .row {
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .media:first-child {
    margin: 10px 0;
  }
  .m-deeplinkteaser--item {
    border-right: 1px solid #6d665a;
    margin: 0;
    padding: 0 20px;
    text-align: center;
    &:last-of-type {
      border: none;
    }
    a {
      display: block;
      margin: 0;
      padding: 0;
    }
    .media-body {
      text-align: left;
      width: inherit;
      h4 {
        font-size: $font-size-xmedium;
        font-weight: 400;
        margin: 5px 0;
      }
      p {
        font-size: $font-size-small;
      }
    }
  }
}
