// homepage intro teaser ce
.s-content.intro-teaser {
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: $screen-md) {
      flex-direction: row;
      gap: 16px;
    }
  }
}
