.m-top-link {
  .top-link {
    background: transparent url('../Images/arrow_top.svg') right 30px top no-repeat;
    background-size: 25px;
    font-size: $font-size-medium;
    padding-top: 25px;
    color: $blue;
    &:hover {
      cursor: pointer;
    }
  }
}
