.tx-supi__youtube {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;

  &-wrapper {
    aspect-ratio: 16/9;
    width: 100%;

    iframe {
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
      margin: 0;
    }

    width: var(--content-width-wide, 100%);
    margin-inline: auto;
  }

  figure,
  .tx-supi__youtube-text {
    grid-column: 1;
  }

  figure {
    aspect-ratio: 16/9;
    width: 100%;
    margin: 0;
    grid-row: 1;

    @media (min-width: $screen-md) {
      grid-row: 1/-1;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .tx-supi__youtube-text {
    position: relative;
    inset: auto;
    margin: auto;
    background: $white-content;

    grid-row: 2;

    @media (min-width: $screen-md) {
      backdrop-filter: blur(2px);
      background: rgba(255, 255, 255, 0.9);
      grid-row: 1/-1;
    }
  }
}
