// logos < 992px
.logos {
  padding: 0;
}
.m-logos {
  text-align: center;
  padding: 0;
  .m-logo--item {
    border-bottom: 1px solid $blue-lines;
    margin: 0;
    padding: 30px;
    float: left;
    width: 50%;
    img {
      max-height: 50px;
      max-width: 100%;
      width: auto;
    }
    &:first-child {
      border-top: 1px solid $blue-lines;
      float: left;
      width: 100%;
    }
    &:nth-child(2n) {
      border-right: 1px solid $blue-lines;
      clear: both;
    }
  }
}

// logos tablet
@media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
}

// logos > 992px
@media screen and (min-width: $screen-md-min) {
  .m-logos {
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    .m-logo--item {
      border: none;
      margin: 0;
      padding: 30px 0;
      &:first-child {
        border: none;
      }
      &:nth-child(2n) {
        border: none;
      }
    }
  }
}
