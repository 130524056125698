.m-toc {
  font-size: 16px;
  font-weight: normal;
  width: auto;
  min-width: 250px;
  display: inline-block;
  summary,
  .summary {
    //cursor: pointer;
    outline: none;
    font-size: inherit;
    &::-webkit-details-marker {
      display: none;
    }
    h4 {
      font-size: inherit;
      margin: 0 0;
    }
  }
  ol,
  ul {
    margin: 0 0;
    li {
      font-size: inherit;
      line-height: 1.3;
      padding-left: 0;
      padding-top: 8px;
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
  }
  border: 1px solid rgba(228, 235, 242, 0.7);
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px 30px 24px 24px;
}
