body {
  background: var(--body-bg, #{$white-content} url('../Images/blatt_oben.png') right top no-repeat);

  &.noscroll {
    overflow: hidden;
  }
}

section {
  padding: 50px 0;

  &.color-brown {
    padding: 5px 0;
  }
}

// headlines

h1,
.header h1 {
  font-size: 25px;
}

h2 {
  font-size: 23px;
}

h1,
h2 {
  font-family: $font-family-sans-serif-2;
  margin: 25px 0;
}

a:focus {
  outline: none;
}

strong,
b {
  font-weight: 700;
}

.fa-2 {
  font-size: $font-size-large;
}

.header {
  background: none;
  height: auto;
  padding-top: 120px;
}

//:target:before {
//  content: '';
//  display: block;
//  height: 90px; /* fixed header height*/
//  margin: -90px 0 0; /* negative fixed header height */
//}

.m-cite cite {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  text-transform: none;
}

.fa-custom-learnlinked {
  background: url('../Images/LearnLinked_logo_bildlogo_1C-50trans.svg');
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 48% 50%;
}

/* link icon */
.cta-link {
  font-size: $font-size-medium;

  &.arrow-brown-full {
    background: transparent url('../Images/arrow_brown_full.svg') right center no-repeat;
    background-size: 20px;
    color: $beige-dark;
    padding-right: 30px;
  }

  &.arrow-brown {
    background: transparent url('../Images/arrow_brown.svg') right center no-repeat;
    background-size: 20px;
    color: $beige-dark;
    padding-right: 30px;
  }

  &.arrow-blue-full {
    background: transparent url('../Images/arrow_blue_full.svg') right center no-repeat;
    background-size: 20px;
    color: $blue;
    padding-right: 30px;
  }

  &.arrow-blue {
    background: transparent url('../Images/arrow_blue.svg') right center no-repeat;
    background-size: 20px;
    color: $blue;
    padding-right: 30px;
  }

  &.arrow-lightblue-full {
    background: transparent url('../Images/arrow_lightblue_full.svg') right center no-repeat;
    background-size: 20px;
    color: $blue-text;
    padding-right: 30px;
  }

  &.arrow-lightblue {
    background: transparent url('../Images/arrow_lightblue.svg') right center no-repeat;
    background-size: 20px;
    color: $blue-text;
    padding-right: 30px;
  }
}

.bg-image {
  height: 605px;

  p {
    font-size: 16px;
  }

  .btn,
  .btn-like {
    margin-top: 30px;
  }

  @media screen and (max-width: $screen-sm-max) {
    background-color: $white-content;
  }
}

.img-invert {
  margin: 40px 0 10px 0;
  filter: invert(100%);
}

.lead {
  font-size: $font-size-medium;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--lead-color, inherit);
}

.header {
  background: url('../Images/blatt_unten.png') left bottom no-repeat;
  height: 550px;
  padding-top: 150px;

  h1 {
    color: $blue-content;
    font-family: $font-family-sans-serif-2;
    font-style: normal;
  }

  h2 {
    color: $blue-content;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    font-size: $font-size-h3;
  }

  p {
    a {
      display: inline-block;
      padding-top: 50px;
    }
  }
}

.content {
  background: var(--content-bg, url('../Images/blatt_unten.png') left bottom no-repeat);
  padding: var(--content-padding, 150px 0 180px 0);

  .col-md-9 {
    & > div {
      margin-bottom: 50px;
    }
  }

  .lead {
    color: $beige-dark;
  }

  .sidebar {
    .ce-right {
      .ce-gallery {
        img {
          margin-left: 0;
        }
      }
    }
  }

  h1 {
    color: $blue-content;
  }

  .intro {
    color: $blue-content;
    font-family: $font-family-sans-serif;
    font-size: $font-size-h2-content;
    line-height: 1.25;
  }

  h2 {
    margin: 50px 0 25px 0;
    font-family: $font-family-sans-serif;
    font-size: $font-size-h2-content;
  }

  h3 {
    margin: 0 0 1px 0;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    font-weight: 700;
  }

  p,
  ul,
  ol {
    margin: 0 0 25px;
  }

  ol {
    padding-left: 0;
    margin-left: 45px;
    counter-reset: item;

    li {
      margin: 0 0 15px 0;
      list-style-type: none;
      counter-increment: item;

      &:before {
        display: inline-block;
        position: relative;
        display: inline-block;
        margin: 0px 25px 0px -40px;
        font-weight: 700;
        text-align: right;
        content: counter(item) '.';
      }
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;

    li {
      padding-left: 45px;
      margin: 0 0 15px 0;

      &:before {
        content: url('../Images/arrow_ul.svg');
        background-size: cover;
        position: relative;
        display: inline-block;
        float: left;
        top: 0;
        margin: 2px 0 0 -42px;
        width: 22px;
        height: 22px;
      }
    }
  }

  figure {
    margin-bottom: 35px;

    figcaption {
      color: $blue;
      font-size: $font-size-medium;
      font-style: italic;
      margin-top: 5px;
    }
  }
}

// buttons
.btn {
  border: none;
  color: $white;
  font-size: $font-size-small;
  text-transform: uppercase;
}

.btn-default {
  padding: 6px 15px 6px 15px;
}

.btn-lg {
  padding: 15px 20px;
}

.btn-like {
  display: inline-block;
  padding: 15px 20px;
}

// table
.table-responsive {
  border: none;
}

.contenttable {
  background-color: $white;
  border-collapse: collapse;
  border-spacing: 0.5rem;
  border: 1px solid #ecf1f1;
  margin: 0 5px 5px 0;
  width: calc(100% - 5px);
  @include box-shadow(5px 5px 0px rgba(239, 243, 243, 1));

  thead {
    tr {
      th {
        border: 1px solid #e6e9e9;
        padding: 20px;
        vertical-align: top;

        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }

      &:first-child th {
        border-top: 0;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px solid #e6e9e9;
        border-bottom: thin short;
        padding: 20px;
        vertical-align: top;

        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }

      &:last-child td {
        border-bottom: 0;
      }
    }
  }
}

.color-beige a.btn,
.color-blue a.btn {
  color: $white;
}

.color-blue .cta-link.btn-like {
  @extend .arrow-lightblue-full;
}

.header .cta-link.btn-like {
  @extend .arrow-brown-full;
}

// media queries
@media screen and (max-width: $screen-xs-max) {
  .bg-image {
    background: none !important;
    height: inherit;
    text-align: center;

    .img-responsive {
      margin: 0 0 40px 0;
      padding: 0;
    }

    h2,
    p {
      text-shadow: none;
    }
  }
  .img-invert {
    margin: 20px 0 10px 0;
    filter: invert(100%);
  }
  .color-transparent {
    color: $gray-base;
  }
}

@media screen and (min-width: $screen-md-min) {
  h1 {
    font-size: $font-size-h1;
  }
  .header h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 40px;
  }
  section {
    &.header,
    &.color-beige,
    &.color-blue {
      .col-md-16 {
        .s-content {
          margin: 0 200px;
          width: auto;
        }
      }
    }
  }
}

iframe {
  border: 0;
}

.learnlinked {
  background: $white-content url(../Images/learnlinked.svg) left center no-repeat;
  padding: 0 0 0 20px;
  background-size: 17px;
  margin-left: 3px;
}
