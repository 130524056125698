.slicknav_menu {
  @media screen and (min-width: $screen-lg-min) {
    display: none;
  }

  padding: 25px 0 0 0;
  position: absolute;
  float: right;
  right: 0;
  width: 100%;
}

.slicknav_menu:before,
.slicknav_menu:after {
  content: ' ';
  display: table;
}

.slicknav_menu:after {
  clear: both;
}

.slicknav_icon {
  background: transparent;
  display: block;
  font-size: 15px;
  margin: 0 auto;
  padding: 0;
  width: 2em;
  height: auto;
}

.slicknav_icon span {
  display: block;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
}

.slicknav_icon span,
.slicknav_icon span:before,
.slicknav_icon span:after {
  width: 100%;
  height: 0.2em;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: translate3d(0, 0, 0);
}

.slicknav_icon span:before,
.slicknav_icon span:after {
  background: #1169a8;
  content: ' ';
  margin: -0.6em 0;
  position: absolute;
}

.slicknav_icon span:after {
  margin-top: 0.6em;
}

a.slicknav_open .slicknav_icon span {
  background: transparent;
}

a.slicknav_open .slicknav_icon span:before {
  -moz-transform: rotate(45deg) translate(0.5em, 0.4em);
  -ms-transform: rotate(45deg) translate(0.5em, 0.4em);
  -o-transform: rotate(45deg) translate(0.5em, 0.4em);
  -webkit-transform: rotate(45deg) translate(0.5em, 0.4em);
  transform: rotate(45deg) translate(0.5em, 0.4em);
}

a.slicknav_open .slicknav_icon span:after {
  -moz-transform: rotate(-45deg) translate(0.45em, -0.35em);
  -ms-transform: rotate(-45deg) translate(0.45em, -0.35em);
  -o-transform: rotate(-45deg) translate(0.45em, -0.35em);
  -webkit-transform: rotate(-45deg) translate(0.45em, -0.35em);
  transform: rotate(-45deg) translate(0.45em, -0.35em);
}

.slicknav_nav {
  clear: both;
  background-color: #fff;
  text-align: left;
}

.slicknav_nav ul,
.slicknav_nav li {
  display: block;
  width: 100%;

  &:after {
    clear: both;
  }
}

.slicknav_nav .slicknav_arrow {
  font-size: 1em;
  float: right;
  border-left: 1px solid #e2ebf5;
  position: absolute;
  height: 65px;
  right: 0px;
  top: 0px;
  width: 60px;
  -webkit-transition: background 0.215s ease-in-out 0s;
  transition: background 0.215s ease-in-out 0s;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -10px 0 0 -3px;
    width: 16px;
    height: 16px;
    opacity: 1;
    z-index: 5;
    -webkit-transition: -webkit-transform 0.25s ease-out 0s;
    transition: transform 0.25s ease-out 0s;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.slicknav_nav .slicknav_item {
  cursor: pointer;
  padding: 0 !important;
  background-color: #f9f8f6;
  border-bottom: 1px solid #e2ebf5;
  color: #1169a8;
}

.slicknav_nav .slicknav_row {
  display: block;
}

.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a {
  display: inline;
}

.slicknav_btn {
  margin: 0 5px 20px;
  padding: 0.438em 0 0.9em 0.625em;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  right: 15px;
}

.slicknav_menu .slicknav_icon-bar {
  background-color: #1169a8;

  .loggedin & {
    background-color: #fff;

    &::before,
    &::after {
      background: #fff;
    }
  }
}

.slicknav_nav {
  color: #1169a8;
  margin: 0;
  padding: 0;
}

.slicknav_nav {
  .navbar-nav {
    li {
      float: none;
      width: 100%;

      .slicknav_parent-link {
        background-color: #2469a8;
        border-bottom: 1px solid #e2ebf5;
        color: #ffffff;
        padding: 20px;
        text-align: left;

        &:hover,
        &:focus {
          background-color: #2469a8;
          color: #ffffff;
        }

        a {
          background-color: #2469a8;
          border-bottom: none;
          color: #ffffff;
          display: block;
        }
      }

      &.active,
      &:focus {
        a {
          //color: #1169a8;
          //&:hover, &:focus {
          //	color: #9c8b4a;
          //	background-color: #efede9;
          //}
        }
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          a {
            background-color: #f9f8f6;
            border-bottom: 1px solid #e2ebf5;
            color: #1169a8;
            padding: 20px 30px;

            &:hover,
            &:focus {
              background-color: #f9f8f6;
              border-bottom: 1px solid #e2ebf5;
              color: #1169a8;
            }

            &:hover {
              color: #9c8b4a;
              background-color: #efede9;
            }
          }
        }
      }
    }

    li.hasSubMenu {
      .slicknav_parent-link {
        background-color: #2469a8;
        border-bottom: 1px solid #e2ebf5;
        color: #ffffff;
        height: 66px;
        padding: 0;
        text-align: left;

        a {
          background-color: #f9f8f6;
          color: #1169a8;
          display: block;
          width: 100%;
          position: absolute;
          //width: calc(100% - 65px);
          &.slicknav_item {
            position: absolute;
          }
        }
      }

      ul {
        li {
          a {
            background-color: #eeede9;
            padding-left: 40px;
          }
        }
      }
    }
  }
}

.slicknav_menu
  > div
  > ul
  > li.dropdown.slicknav_parent.slicknav_open
  > ul
  > li.hasSubMenu.slicknav_parent.slicknav_collapsed
  > span
  > a.slicknav_item
  > span
  > i {
  color: #9c8b4a;
}

.slicknav_menu
  > div
  > ul
  > li.dropdown.slicknav_parent.slicknav_open
  > ul
  > li.hasSubMenu.slicknav_parent.slicknav_open
  > span
  > a.slicknav_item
  > span
  > i {
  margin: -10px 0 0 -7px;
  color: #9c8b4a;
}

.slicknav_menu > div > ul > li.dropdown.slicknav_parent.slicknav_open > span > a.slicknav_item > span > i {
  margin: -10px 0 0 -7px;
}

/* Anpassung Hamburger Icon */
.slicknav_icon span.slicknav_icon-bar {
  display: none;

  &:first-of-type {
    display: block;
  }
}
