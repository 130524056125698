.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer;
  .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em;
  }
}
.slicknav_menu {
  *zoom: 1;
  .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left;
  }
  .slicknav_icon {
    float: left;
    margin: 0.188em 0 0 0.438em;
  }
  .slicknav_icon-bar {
  }
  &:before {
    content: ' ';
    display: table;
  }
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}
.slicknav_nav {
  clear: both;
  ul {
    display: block;
  }
  li {
    display: block;
  }
  .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em;
  }
  .slicknav_item {
    cursor: pointer;
    a {
      display: inline;
    }
  }
  .slicknav_row {
    display: block;
  }
  a {
    display: block;
  }
  .slicknav_parent-link a {
    display: inline;
  }
}
.slicknav_brand {
  float: left;
}
