.m-organizationfacts {
  text-align: center;
  .fact {
    padding: 20px 0;
    float: left;
    width: 50%;
    @media (max-width: 992px) {
      &:nth-child(3n) {
        clear: both;
      }
      &:nth-child(1) {
        border-bottom: 1px solid #d4ccb2;
        border-right: 1px solid #d4ccb2;
      }
      &:nth-child(2) {
        border-bottom: 1px solid #d4ccb2;
      }
      &:nth-child(3) {
        border-right: 1px solid #d4ccb2;
      }
    }
    .fact-value {
      color: $beige-dark;
      font-size: 23px;
      font-weight: 100;
    }
    .fact-label {
      font-size: 16px;
    }
  }
}

@media (min-width: 992px) {
  .m-organizationfacts {
    display: -webkit-flex;
    display: -ms-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    .fact {
      margin: 20px;
      .fact-value {
        color: $beige-dark;
        font-size: 60px;
        font-weight: 100;
      }
      .fact-label {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) and (orientation: landscape) {
  .m-organizationfacts {
    margin-left: -100px;
    margin-right: -100px;
  }
}
