.m-newsletter {
  background-color: $blue-form-bg;
  border: 1px solid #ecf1f1;
  color: $blue;
  margin: 0 5px 40px 0;
  width: calc(100% - 5px);
  @include box-shadow(5px 5px 0px rgba(239, 243, 243, 1));
  padding: 20px 10px;
  p {
    color: $blue;
  }
  label {
    display: none;
  }
  input {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    width: 100%;
    @include box-shadow(2px 2px 3px rgba(196, 200, 203, 1));
  }
  .radio {
    p {
      margin-bottom: 5px;
    }
    label {
      display: block;
    }
    input {
      box-shadow: none;
    }
  }
  .btn-like {
    padding-left: 0;
  }
  button {
    background-color: rgba(178, 190, 199, 1) !important;
  }
}

@media screen and (min-width: $screen-md-min) {
  .m-newsletter {
    padding: 20px 40px;
    input {
      width: 80%;
    }
  }
}
