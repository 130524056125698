.lightbox {
  &-button,
  &-close {
    padding: 0;
    border: none;
    background: transparent;
  }
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0 0;
  border: none;
}
