.slick-slide:focus {
  outline: none;
}
.m-cite {
  text-align: center;
  cite {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-size: 15px;
    text-transform: none;
  }
  @media (min-width: 768px) {
    cite {
      font-size: 30px;
    }
  }
  a:focus,
  m-cite--item:focus {
    outline: 0;
  }
}

.m-cite--item {
  font-size: $font-size-small;
  text-align: center;
  text-transform: uppercase;
  margin: 0 40px;
  .media-middle {
    display: inline-block;
    width: inherit;
  }
  img {
    display: inline;
  }
  .media {
    display: block;
  }
  @media (min-width: 992px) {
    .media {
      display: inline-block;
    }
  }
  .media-heading {
    display: inline;
    margin: 0 10px 0 0;
    span {
      padding: 0 10px;
      &:before {
        content: '| ';
        padding: 0 10px 0 0;
      }
    }
  }
}
