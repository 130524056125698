.ce-gallery {
  img {
    max-width: 100%;
    height: auto;
    width: 100%;
  }
  figure {
    margin-bottom: 35px;
    figcaption {
      margin-bottom: 15px;
    }
  }
  .ce-media {
    img {
      float: none;
      display: block;
      height: auto;
      max-width: 100%;
      margin-bottom: 35px;
    }
  }
}
.ce-left .ce-gallery,
.ce-column {
  float: none;
}
.ce-center .ce-outer {
  position: relative;
  float: right;
  right: 50%;
}
.ce-center .ce-inner {
  position: relative;
  float: right;
  right: -50%;
}
.ce-right .ce-gallery {
  float: none;
}

@media screen and (min-width: $screen-sm-min) {
  .ce-align-left {
    text-align: left;
  }
  .ce-align-center {
    text-align: center;
  }
  .ce-align-right {
    text-align: right;
  }

  .ce-table td,
  .ce-table th {
    vertical-align: top;
  }

  .ce-textpic,
  .ce-nowrap .ce-bodytext,
  .ce-gallery,
  .ce-row,
  .ce-uploads li,
  .ce-uploads div {
    overflow: hidden;
  }

  .ce-left .ce-gallery,
  .ce-column {
    float: left;
  }
  .ce-center .ce-outer {
    position: relative;
    float: right;
    right: 50%;
  }
  .ce-center .ce-inner {
    position: relative;
    float: right;
    right: -50%;
  }
  .ce-right .ce-gallery {
    float: right;
  }

  .ce-gallery figure {
    display: table;
    margin: 0;
  }
  .ce-gallery figcaption {
    display: table-caption;
    caption-side: bottom;
  }
  .ce-gallery img {
    display: block;
    width: auto;
  }
  .ce-gallery iframe {
    border-width: 0;
  }
  .ce-border img,
  .ce-border iframe {
    border: 1px solid black;
    padding: 1px;
  }
  .ce-right .ce-gallery img {
    margin-left: 15px;
  }
  .ce-column:last-child {
    margin-right: 0;
  }
  .ce-row:last-child {
    margin-bottom: 0;
  }

  .ce-above .ce-bodytext {
    clear: both;
  }
  .ce-intext.ce-left ol,
  .ce-intext.ce-left ul {
    padding-left: 40px;
    overflow: auto;
  }

  .ce-uploads {
    margin: 0;
    padding: 0;
  }
  .ce-uploads li {
    list-style: none outside none;
    margin: 1em 0;
  }
  .ce-uploads img {
    float: left;
    padding-right: 1em;
    vertical-align: top;
  }
  .ce-uploads span {
    display: block;
  }
}
