.color-transparent {
  color: $white;
}
.color-beige {
  color: $gray-base;
  background-color: $beige;
  a {
    color: $gray-base;
  }
  .lead {
    color: $beige-dark;
  }
  h2 {
    color: $blue-content;
  }
}
.color-blue {
  color: $white;
  background-color: $blue-content;
  a {
    color: $blue-text;
  }
  .lead {
    color: $blue-text;
  }
}
.color-light-blue {
  background-color: $blue-footer-bg;
}
.color-brown {
  color: $white;
  background-color: $brown;
  a {
    color: $white;
  }
}
.color-white {
  background-color: $white-content !important;
}
