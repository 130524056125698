.content {
  .local-nav {
    display: none;
  }
}
@media (min-width: 1200px) {
  .content {
    .local-nav {
      display: block;
      margin: 0;
      li {
        border: none;
        background-color: #f9fbfb;
        padding: 0;
        margin: 0;
        min-width: 250px;
        position: relative;
        &:first-of-type {
          background-color: transparent;
        }
        &:before {
          display: none;
        }
        &.active {
          > a {
            font-weight: 700;
          }
        }
        a {
          border: none;
          border-bottom: 1px solid #e2ebf5;
          color: #1b547e;
          display: block;
          font-size: 17px;
          padding: 20px;
          &.active,
          &.current {
            &.sub {
              &:after {
                content: '\f107';
                color: $beige-dark;
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                position: absolute;
                float: right;
                display: block;
                border-left: 1px solid #e2ebf5;
                width: 60px;
                height: 100%;
                text-align: center;
                right: 0;
                top: 0;
                padding-top: 20px;
                overflow: hidden;
              }
            }
          }
          &.current {
            background-color: $white;
            padding-right: 60px;
          }
          &.sub {
            padding: 20px 61px 20px 20px;
            &:after {
              content: '\f105';
              color: $beige-dark;
              font-family: FontAwesome;
              font-style: normal;
              font-weight: normal;
              text-decoration: inherit;
              position: absolute;
              float: right;
              display: block;
              border-left: 1px solid #e2ebf5;
              width: 60px;
              height: 100%;
              text-align: center;
              right: 0;
              top: 0;
              padding-top: 20px;
              overflow: hidden;
            }
          }
        }
        &.sub {
          ul.lvl-1 {
            margin: 0;
            li {
              &:first-of-type {
                background-color: #f9fbfb;
              }
              &.active {
                a {
                  color: $beige-dark;
                  font-weight: 700;
                }
              }
              a {
                padding-left: 40px;
              }

              ul.lvl-2 {
                margin: 0;
                li {
                  a {
                    padding-left: 60px;

                    &:not(.current) {
                      font-weight: normal;
                      color: $blue-content;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &:first-of-type {
        margin-top: 50px;
      }
    }
  }
}
